// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.main-text {
  font-size: $large-font-size;
  font-weight: bold;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
